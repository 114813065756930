//
// Bootstrap
//

@import '~bootstrap/scss/bootstrap';
@import './bootstrap/reboot';
@import '~highlight.js/styles/default';
@import '~font-awesome/scss/font-awesome.scss';
@import '~bootstrap-select/dist/css/bootstrap-select.min.css';
@import '~typeahead.js-bootstrap4-css/typeaheadjs.css';
@import '~datatables.net-bs4/css/dataTables.bootstrap4.css';
@import '~datatables.net-buttons-bs4/css/buttons.bootstrap4.css';
@import '~datatables.net-select-bs4/css/select.bootstrap4.css';

/* The placeholders are a bit too strong by default */
.form-control::placeholder { color: #aaa; opacity: 1; }  /* Standard-adhering browsers */
.form-control::-webkit-input-placeholder { color: #aaa; }  /* WebKit, Blink, Edge */
.form-control:-moz-placeholder { color: #aaa; }  /* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder { color: #aaa; }  /* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder { color: #aaa; }  /* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder { color: #aaa; }  /* Microsoft Edge */

.year {
  margin-top: 8px;
  margin-bottom: 8px;
}
.form-fix {
  margin-top: 20px;
}

.line-manager {
  margin-bottom: 2rem;
}

body {
  background-color: #efefef;
}
.navbar {
  -webkit-box-shadow: 0 8px 6px -6px #999;
  -moz-box-shadow: 0 8px 6px -6px #999;
  box-shadow: 0 8px 6px -6px #999;
}

.jumbotron {
  background-color: #ffffff;
}

.nav-item {
  margin-left: 4px;
  margin-right: 4px;
}

.table td {
  vertical-align: middle;
}

canvas {
  display: block;
  background-color: rgb(239, 239, 239);
}

.balance-change {
  font-weight: bold;
}

form .line-countdown {
  min-width: 40px;
}

.btn:disabled {
  cursor: not-allowed;
}

*, ::before, ::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#loader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #e7e6dd;
}

.loading div {
  position: absolute;
  width: 100vw;
  left: calc(-50vw + 50px);
  bottom: -20vh;
  padding: 0 20px;
  text-align: center;
}

.loading {
  display: block;
  margin: auto;
  position: relative;
  width: 100px;
  height: 100px;
}
.loading span {
  display: block;
  position: absolute;
  width: 50%;
  height: 50%;
}
.loading span:nth-child(1) {
  top: 0;
  left: 0;
  background-color: rgba(66, 133, 244, 0.75);
  -webkit-animation: move1 2s linear infinite;
          animation: move1 2s linear infinite;
}
.loading span:nth-child(2) {
  top: 0;
  right: 0;
  background-color: rgba(219, 68, 55, 0.75);
  -webkit-animation: move2 2s linear infinite;
          animation: move2 2s linear infinite;
}
.loading span:nth-child(3) {
  bottom: 0;
  left: 0;
  background-color: rgba(244, 180, 0, 0.75);
  -webkit-animation: move3 2s linear infinite;
          animation: move3 2s linear infinite;
}
.loading span:nth-child(4) {
  bottom: 0;
  right: 0;
  background-color: rgba(15, 157, 88, 0.75);
  -webkit-animation: move4 2s linear infinite;
          animation: move4 2s linear infinite;
}

@-webkit-keyframes move1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
    z-index: 10;
  }
  25% {
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0);
  }
  50% {
    -webkit-transform: translate(100%, 100%);
            transform: translate(100%, 100%);
  }
  75% {
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
    z-index: 0;
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

@keyframes move1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
    z-index: 10;
  }
  25% {
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0);
  }
  50% {
    -webkit-transform: translate(100%, 100%);
            transform: translate(100%, 100%);
  }
  75% {
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
    z-index: 0;
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
@-webkit-keyframes move2 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  25% {
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
  }
  50% {
    -webkit-transform: translate(-100%, 100%);
            transform: translate(-100%, 100%);
    z-index: 0;
  }
  75% {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
    z-index: 10;
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
@keyframes move2 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  25% {
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
  }
  50% {
    -webkit-transform: translate(-100%, 100%);
            transform: translate(-100%, 100%);
    z-index: 0;
  }
  75% {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
    z-index: 10;
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
@-webkit-keyframes move3 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  25% {
    -webkit-transform: translate(0, -100%);
            transform: translate(0, -100%);
    z-index: 10;
  }
  50% {
    -webkit-transform: translate(100%, -100%);
            transform: translate(100%, -100%);
  }
  75% {
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
    z-index: 0;
  }
}
@keyframes move3 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  25% {
    -webkit-transform: translate(0, -100%);
            transform: translate(0, -100%);
    z-index: 10;
  }
  50% {
    -webkit-transform: translate(100%, -100%);
            transform: translate(100%, -100%);
  }
  75% {
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
    z-index: 0;
  }
}
@-webkit-keyframes move4 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  25% {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
    z-index: 0;
  }
  50% {
    -webkit-transform: translate(-100%, -100%);
            transform: translate(-100%, -100%);
    z-index: 10;
  }
  75% {
    -webkit-transform: translate(0, -100%);
            transform: translate(0, -100%);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
@keyframes move4 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  25% {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
    z-index: 0;
  }
  50% {
    -webkit-transform: translate(-100%, -100%);
            transform: translate(-100%, -100%);
    z-index: 10;
  }
  75% {
    -webkit-transform: translate(0, -100%);
            transform: translate(0, -100%);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

.navbar-brand {
  padding: 0;

  img {
    height: 40px;
  }
}

// Very basic footer that makes the content of the footer tag look okay even if it's just text.
.basic-footer {
  text-align: center;
  font-size: 0.8em;
  margin-top: 10px;
  padding: 5px;
}

#payment-paypal {
  height: 28rem;
}

.paypal-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.alert-message {
  color: rgb(22, 22, 22);
  background-color: #FFB55F;
  border-color: #D77300;
}

.typeDisplay {
  display: none;
}

/* Typeahead fix */
.tt-selectable {
  cursor: pointer;
}

#resellerAgreementBody {
  overflow: scroll;
  overflow-x: hidden;
  height: calc(100vh - 250px);
  min-height: 300px;
}

/* Fix for bootstrap select "vanishing" text-muted items on disabled selected item */
.bootstrap-select .dropdown-menu li.active.disabled small.text-muted {
  color: #6c757d!important;
}

.break-word {
  word-break: break-word;
}

.num-change-request[data-relevant=true] {
  background: #fff3cd;
}

.num-change-request[data-very-important=true] {
  background: #ffcccc;
}

.table td small {
  line-height: 1;
  display: inline-block;
}

.table.dense-table td, .table.dense-table th {
  padding: 0;
  font-size: 72%;
}

.table.dense-table td {
  border-bottom: 1px solid #dee2e6;
}

.text-muted {
  color: #a4a4a4 !important;
}

.payment-credits-table {
  border-collapse: separate;
  border-spacing: 10px;

  td:first-child {
    padding-bottom: 7px;
  }

  td:nth-child(2) label {
    cursor: pointer;
    color: #007bff;

    &:hover{
      color: #0056b3;
    }
  }

  tr.selected {
    font-weight: bold;
  }
}

.rotate-180 {
  rotate: 180deg;
}

.usecase-price {
  display: inline-block;
  width: 50px;
  margin-right: 14px;
}

.usecase-subtitle {
  display: block;
  font-size: 80%;
  margin-left: 20px;
  position: relative;
  white-space: normal;

  &::before {
    position: absolute;
    top: 0;
    left: -15px;
    content: "└";
  }
}

// Hide subtitle within dropdown button itself
.filter-option-inner-inner .usecase-subtitle {
  display: none;
}

// Inside the select field itself, outside of the dropdown, use tighter representation
.bootstrap-select .dropdown-toggle .usecase-price {
  margin-right: 0;
  width: auto;
}

@import './dataTables';
@import './kpi';

.strikethrough {
  position: relative;
}
.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid;
  border-color: inherit;

  -webkit-transform:rotate(-15deg);
  -moz-transform:rotate(-15deg);
  -ms-transform:rotate(-15deg);
  -o-transform:rotate(-15deg);
  transform:rotate(-15deg);
}

.raised-badge {
  position: absolute;
  transform: translate(-7px, -3px);
  border-radius: 9999px;
  padding: 2px 5px;
}

.dt-single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
